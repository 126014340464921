import * as React from 'react';
import { FC } from 'react';
import { Box, Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { makeStyles } from '@material-ui/core/styles';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    FilterForm,
    FilterContext,
    InputProps,
    ListBase,
    ListProps,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextInput,
    Title,
    TopToolbar,
    useListContext,
    useTranslate,
} from 'react-admin';

import ProgramList from './ProgramList';

const ListActions: FC<any> = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const productFilters = [
    <TextInput
        variant="outlined"
        InputLabelProps={{
            shrink: true
        }}
        placeholder="Search epg ..."
        label="Search epg ..."
        source="q"
        alwaysOn
    />
];

const ResourceList: FC<ListProps> = props => {
    return (
        <div style={{ paddingLeft: 20 }}>
            <ListBase
                perPage={10}
                sort={{ field: 'reference', order: 'ASC' }}
                {...props}
            >
                <Title defaultTitle="EPG TV" />
                <Grid container spacing={3}>
                    <Grid item md={9}>
                        <FilterContext.Provider value={productFilters}>
                            <FilterForm />
                        </FilterContext.Provider>
                    </Grid>
                    <Grid item md={3}>
                        <ListActions />
                    </Grid>
                </Grid>
                <ProgramList />
                <Pagination rowsPerPageOptions={[5, 10, 20]} />
            </ListBase>
        </div>
    );

};

export default ResourceList;