import React, { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme, createStyles, Divider, Button, Dialog, CircularProgress, DialogActions, DialogContent, List, Backdrop, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Dropzone from 'react-dropzone';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { BASE_URL } from "../../../config";

type Inputs = {
    files: []
};

const ImportEPG: FC = () => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
            description: {
                fontSize: 12,
                color: "grey",
                marginTop: -5
            },
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                color: '#fff',
            },
            dropzone: {
                border: '1px dashed #ddd',
                padding: 30,
                borderRadius: 6,
                height: 50,
                width: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
        })
    );
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const [changeFiles, setChangeFiles] = useState<any>([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const files = changeFiles.map(file => (
        <ListItem key={file.name}>
            <ListItemAvatar>
                <Avatar>
                    <FileCopyIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`${file.size} Bytes`} />
        </ListItem>
    ));

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        setOpen(false);
        setOpenBackDrop(true);

        data.files = changeFiles;

        data.files.forEach((item, i) => {
            formData.append("files", item)
        })

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/import/epg/dga`, requestOptions)
            .then((data) => {
                if(data.status===201){
                    notify('Your epg was successfully imported', 'info');
                    setOpenBackDrop(false);
                    history.push("/epg");
                }else{
                    notify('Error: Your EPG was not successfully created', 'warning');
                }
            });
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Import EPG
            </Button>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Dialog
                    open={open}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <Dropzone onDrop={useCallback(acceptedFiles => {
                            setChangeFiles(acceptedFiles);
                        }, [])}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className={classes.dropzone}>Drag and drop some files here, or click to select files</p>
                                </div>
                            )}
                        </Dropzone>
                        <div>
                            <List>
                                {files}
                            </List>
                        </div>
                        <div style={{ padding: 20, marginTop: 10 }}>
                            <Divider />
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                color="primary"
                                variant="contained"
                            >
                                Import EPG
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                <Backdrop className={classes.backdrop} open={openBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </form>
        </div>
    );
}

export default ImportEPG;