// edit geners

import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useListParams } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types
import { AppState } from '../../types/Theme';
import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    name: string
};

const Edit: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30
            },
            formControl: {
                minWidth: 130
            }
        })
    );
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory();
    const version = useVersion();

    const { id } = useParams();

    const [genreDetails, setGenreDetails] = useState<any>({});

    //fetch genres
    const fetchGenres = useCallback(async () => {
        await dataProvider.getOne(
            'genres',
            {
                id: id
            },
            {
                onSuccess: async (response) => {
                    setGenreDetails(response.data);
                    setValue("name", response.data.name);
                }
            }
        );

    }, []);

    useEffect(() => {
        fetchGenres();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("name", data.name);

        dataProvider.update<any>(
            'genres',
            {
                id: id,
                data: formData,
                previousData: {
                    id: id,
                    name: genreDetails.name,
                }
            }).then((res) => {
                refresh();
                notify("Genre was successfully updated");
                history.push("/genres");
            }).catch((errors: any) => {
                notify("Genre was not successfully updated");
            })
    }

    return (
        <div className={classes.root}>
            <Title title="Edit Package" />
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} />
                    <Grid container spacing={7}>
                        <Grid item md={4}>
                            <TextField
                                id="name"
                                InputLabelProps={{ shrink: true }}
                                label="Genre Name"
                                placeholder="Enter Genre Name"
                                {...register("name")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <div style={{ padding: 20 }}>
                        <Divider />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                disabled={!isDirty}
                                size="large"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    )
}

export default Edit;