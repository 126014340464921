//users create

import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider, warning } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Divider, InputLabel, MenuItem, Select, Button, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types
import { AppState } from '../../types/Theme';
import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    email: string,
    role: string,
    phone: string,
    address: string,
};

const Create: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30
            },
        })
    );
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory();

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("email", data.email);
        formData.append("role", data.role);
        formData.append("phone", data.phone);
        formData.append("address", data.address);

        dataProvider.create<any>(
            'users/cd/create',
            {
                data: formData
            }).then((res) => {
                refresh();
                notify("User was successfully invited");
                history.push("/users/cd");
            }).catch((errors) => {
                notify("User was not successfully invited", 'warning');
            })
    }

    return (
        <div className={classes.root}>
            <Title title="Invite User" />
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} />
                    <Grid container spacing={1}>
                        <Grid item md={2}>
                            <TextField
                                id="email"
                                label="Email"
                                {...register("email")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="phone"
                                label="Phone"
                                {...register("phone")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                id="address"
                                label="Address"
                                {...register("address")}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TitleSection title="Permissions" description="Permissions" paddingBottom={10} paddingLeft={1} paddingTop={30} />
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <TextField
                                id="role"
                                select
                                label="Role"
                                variant="outlined"
                                {...register("role")}
                                fullWidth
                            >
                                <MenuItem value="IT">IT</MenuItem>
                                <MenuItem value="Commercial">Commercial</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <div style={{ padding: 20 }}>
                        <Divider />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Button
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                Invite User
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    )
}

export default Create;