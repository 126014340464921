import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, Typography, TextField, Divider, Button, Box, Tabs, Tab, Card, CardContent, Avatar, CardActions } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// import types
import { AppState } from '../../types/Theme';

//import dialogs
import ImportEpg from './dialogs/ImportEpg';

const Imports: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30
            },
            card: {
                borderRadius: 20
            },
            avatar: {
                marginTop: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            avatarSize: {
                width: theme.spacing(10),
                height: theme.spacing(10)
            },
            actions: {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 15
            },
            title: {
                marginTop: 20,
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "bold"
            },
            description: {
                textAlign: "center",
                fontSize: 10,
                color: "grey",
                marginTop: 5
            },
        })
    );
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Title title="Imports" />
            <Grid container spacing={2}>
                <Grid item md={2}>
                    <Card className={classes.card}>
                        <CardContent>
                            <div className={classes.avatar}>
                                <Avatar alt="EPG" className={classes.avatarSize} />
                            </div>
                            <Typography className={classes.title} component="p">
                                Import EPG
                            </Typography>
                            <Typography className={classes.description} component="p">
                                Import EPG for multiple or single channel
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.actions}>
                            <ImportEpg />
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Imports;