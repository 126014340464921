import { ReactElement } from 'react';
import { FC } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    ListBase,
    Pagination,
    TextField,
    EditButton,
    TopToolbar,
    CreateButton,
    useListContext,
} from 'react-admin';
import { Chip, Typography, Box, Avatar, Card, CardActionArea, Grid, makeStyles } from '@material-ui/core';

//import types
// import theme from '../../types/Theme';

import GridList from './GridList';

const ListActions: FC<any> = () => (
    <TopToolbar>
        <CreateButton basePath="/users/cd" label="Invite User" />
    </TopToolbar>
);

const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <Box style={{ padding: 20 }} textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No Users available.
            </Typography>
            <Typography variant="body1">
                Do you want to add one ?
            </Typography>
        </Box>
    );
};

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <div style={{ paddingLeft: 25 }}>
            <ListBase
                title="Users"
                empty={<Empty />}
                {...props}
                perPage={25}
            >
                <ListActions />
                <GridList />
                <Pagination rowsPerPageOptions={[5, 10, 20]} />
            </ListBase>
        </div >
    )
}
export default ResourceList;