import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    useListContext,
    DatagridProps,
    Identifier,
} from 'react-admin';
import MuiGridList from '@material-ui/core/GridList';
import { Typography, Avatar, Button, Card, CardActions, CardContent, Grid, GridListTile } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        borderRadius: 20
    },
    avatar: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarSize: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        color: "white",
        backgroundColor: "orange"
    },
    title: {
        fontSize: 14,
        padding: 15,
        textAlign: "center",
        marginTop: -10
    },
    description: {
        fontSize: 10,
        textAlign: "center",
        marginTop: -15
    },
    actions: {
        display: 'flex',
        justifyItems: 'space-between',
        marginBottom: 10
    },
}));

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: any) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 5;
};

const LoadingGridList: FC<DatagridProps & { nbItems?: number }> = ({
    width,
    nbItems = 14,
}) => {
    const classes = useStyles();

    return (
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    );

};

const LoadedGridList: FC<DatagridProps> = () => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();
    if (!ids || !data) return null;

    return (
        <Grid container spacing={2}>
            {ids.map((id: Identifier) => (
                <Grid item md={2}>
                    <Card className={classes.card}>
                        <CardContent>
                            <div className={classes.avatar}>
                                <Avatar alt="User" className={classes.avatarSize}>HK</Avatar>
                            </div>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {data[id].email}
                            </Typography>
                            <Typography className={classes.description} color="textSecondary" gutterBottom>
                                {data[id].email}
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.actions}>
                            <Button href={`/#/users/${data[id]._id}`} size="small" color="primary">Edit</Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

const GridList: FC = () => {
    const { loaded } = useListContext();

    return loaded ? (
        <LoadedGridList />
    ) : (
        <LoadingGridList />
    );

};

export default GridList;