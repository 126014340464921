import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import {
    makeStyles,
    createStyles,
    Theme,
    Grid,
    TextField,
    Divider,
    Button,
    Avatar,
    ListItem, ListItemAvatar, ListItemText
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Banner from '../../assets/images/banner_placeholder.png';

// import types
import { AppState } from '../../types/Theme';

// import components
import TitleSection from '../../components/title/TitleSection';
import Dropzone from "react-dropzone";
import { BASE_URL } from "../../config";

type Inputs = {
    content_title: string,
    content_description: string,
    content_images: any,
    content_banner: object
};

const EditChannel: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30,
                "& .MuiFormHelperText-root": {
                    color: "red"
                }
            },
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
            flex: {
                display: 'flex'
            },
            dropzone: {
                border: '1px dashed #ddd',
                padding: 30,
                borderRadius: 6,
                height: 50,
                width: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            images: {
                maxWidth: 150,
                height: 120,
                marginRight: 15,
                objectFit: 'contain',
            }
        })
    );
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const { id } = useParams();
    const [disabled, setDisabled] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = useState("");
    const [imagesUrl, setImagesUrl] = useState<any>([]);
    const [changeFiles, setChangeFiles] = useState<any>([]);
    const [changeImage, setChangeImage] = useState({});

    const handleImage = (e) => {
        const url = createUrl(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
        setDisabled(false);
    }

    const createUrl = (file) => {
        const url = URL.createObjectURL(file);
        return url;
    }

    const showedImages = changeFiles.map(file => (
        <ListItem key={file.name}>
            <ListItemAvatar>
                <Avatar>
                    <img width={150} src={createUrl(file)} />
                    {/*{createUrl(file)}*/}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`${file.size} Bytes`} />
        </ListItem>
    ));

    const handleDelete = (file) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            }
        };

        fetch(`${BASE_URL}/api/v1/content/images/${id}?content_images=${file}`, requestOptions)
            .then((data) => {
                if (data.status === 200) {
                    const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                    notify('Your content image was successfully deleted', 'info');
                    refresh();
                } else {
                    notify('Error: Your EPG was not successfully created', 'warning');
                }
            });
    };

    //fetch channel data
    const fetchData = useCallback(async () => {
        await dataProvider.getOne(
            'content',
            {
                id: id
            },
            {
                onSuccess: async (response) => {
                    setValue("content_title", response.data.content_title);
                    setValue("content_description", response.data.content_description);
                    setImagesUrl(response.data.content_images);
                    setImageUrl(response.data.content_banner);
                }
            }
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchData();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.content_images = changeFiles;
        data.content_banner = changeImage;

        data.content_images.forEach((item) => {
            formData.append("content_images", item);
        })

        formData.append("content_title", data.content_title);
        formData.append("content_description", data.content_description);
        if (Object.keys(data.content_banner).length === 0 && data.content_banner.constructor === Object === false) {
            formData.append("content_banner", data.content_banner);
        }

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/content/${id}`, requestOptions)
            .then((data) => {
                if (data.status === 200) {
                    const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                    notify('Your content was successfully created', 'info');
                    history.push("/content");
                } else {
                    notify('Error: Your content was not successfully updated', 'warning');
                }
            });
    }

    return (
        <div className={classes.root}>
            <Title title="Create Channel" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <TitleSection title="Basic Details" description="Basic Details" paddingBottom={20} paddingLeft={1} paddingTop={0} />
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <TextField
                                    id="title"
                                    placeholder="Enter Content Title"
                                    label="Content Title"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("content_title")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <div style={{ padding: 10 }}></div>
                        <Grid container spacing={2}>
                            <Grid item md={10}>
                                <TextField
                                    id="content_description"
                                    placeholder="Enter Content Description"
                                    label="Content Description"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("content_description")}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                        <TitleSection title="Media & Options" description="Media & Options" paddingBottom={20} paddingLeft={1} paddingTop={30} />
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className={classes.flex}>
                                    <div>
                                        {imageUrl ?
                                            <img width={200} height={120} style={{ objectFit: "cover" }} src={imageUrl} />
                                            :
                                            <img src={Banner} />
                                        }
                                    </div>
                                    <div style={{ alignSelf: 'center' }}>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                        >
                                            Upload Banner
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className={classes.flex}>
                                    <div>
                                        {imagesUrl.length ? <p>Saved Images</p> : null}
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 30, marginBottom: 20 }}>
                                            {imagesUrl.map(file => (
                                                <>
                                                    <img className={classes.images} src={file} />
                                                    <div>
                                                        <IconButton aria-label="delete" onClick={() => handleDelete(file)} style={{ marginLeft: -10, marginRight: 20 }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                        <Dropzone
                                            onDrop={useCallback(acceptedFiles => {
                                                setChangeFiles(acceptedFiles);
                                            }, [])}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p className={classes.dropzone}>Drag and drop other images here, or click to select files</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        New Images
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop: 10 }}>
                                            {changeFiles.map(file => (
                                                <img className={classes.images} src={createUrl(file)} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ padding: 20 }}>
                            <Divider />
                        </div>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>
        </div>
    )

}

export default EditChannel;