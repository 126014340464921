//edit channels

import React, { FC, useCallback, useEffect, useState } from 'react';
import { Title, useDataProvider, useNotify, useRefresh, useVersion } from 'react-admin';
import { Avatar, Button, createStyles, Divider, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SubmitHandler, useForm } from "react-hook-form";
import CreateGenre from './dialogs/CreateGenre';

// import types
import { AppState } from '../../types/Theme';

// import components
import TitleSection from '../../components/title/TitleSection';

import { BASE_ASSETS_URL } from "../../config"

type Inputs = {
    epg_map_id: string,
    channel_code: string,
    title: string,
    channel_number: string,
    channel_description: string,
    icon_path: object,
    genre: any,
    country: any,
    locale: string
};

const EditChannel: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30,
                "& .MuiFormHelperText-root": {
                    color: "red"
                }
            },
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(7),
                height: theme.spacing(7),
            },
            flex: {
                display: 'flex'
            },
            formControl: {
                minWidth: 130
            },
        })
    );
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const { id } = useParams();
    const [disabled, setDisabled] = useState<boolean>(true);

    const [imageUrl, setImageUrl] = useState("");
    const [changeimage, setChangeImage] = useState({});

    const [genres, setGenres] = useState<any>([]);
    const [selectedGenres, setSelectedGenres] = useState<any>([]);

    const [countries, setCountries] = useState<any>([]);
    const [selectedCountries, setSelectedCountries] = useState<any>({});

    const [channelDetails, setChannelDetails] = React.useState<any>({});

    const handleImage = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
        setDisabled(false);
    }

    const fetchGenres = useCallback(async () => {
        await dataProvider.getList<any>(
            'genres',
            {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            },
            {
                onSuccess: (response) => {
                    setGenres(response.data);
                }
            }
        );

    }, [dataProvider]);

    const fetchCountries = useCallback(async () => {
        await dataProvider.getList<any>(
            'country',
            {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            },
            {
                onSuccess: (response) => {
                    setCountries(response.data);
                }
            }
        );
    }, [dataProvider]);

    //fetch channel data
    const fetchData = useCallback(async () => {
        await dataProvider.getOne(
            'channels',
            {
                id: id
            },
            {
                onSuccess: (response) => {
                    setChannelDetails(response.data);
                    setValue("epg_map_id", response.data.epg_map_id);
                    setValue("locale", response.data.locale);
                    setValue("title", response.data.title);
                    setValue("channel_number", response.data.channel_number);
                    setValue("channel_code", response.data.channel_code);
                    setValue("channel_description", response.data.channel_description);
                    setImageUrl(response.data.icon_path);
                    setSelectedGenres(response.data.genre);
                    setSelectedCountries(response.data.country[0]);
                }
            }
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchData();
        fetchCountries();
        fetchGenres();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, setValue, formState: { errors, isDirty } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.genre = selectedGenres;
        data.country = selectedCountries;
        data.icon_path = changeimage;

        formData.append("title", data.title);
        formData.append("channel_number", data.channel_number);
        formData.append("epg_map_id", data.epg_map_id);
        formData.append("channel_code", data.channel_code);
        formData.append("locale", data.locale);
        formData.append("channel_description", data.channel_description);
        if (Object.keys(data.icon_path).length === 0 && data.icon_path.constructor === Object === false) {
            formData.append("icon_path", data.icon_path);
        }
        formData.append("genre", JSON.stringify(data.genre));
        formData.append("country", JSON.stringify(data.country));

        dataProvider.update(
            'channels',
            {
                id: id,
                data: formData,
                previousData: {
                    id: id
                }
            }).then((res) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your channel was successfully updated', 'info');
                history.push("/channels");
            }).catch((errors) => {
                notify('Error: Your channel was not successfully updated', 'warning');
            })
    }

    return (
        <div className={classes.root}>
            <Title title="Edit Channel" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <TitleSection title="Basic Details" description="Basic Details" paddingBottom={20}
                            paddingLeft={1} paddingTop={0} />
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <TextField
                                    id="title"
                                    placeholder="Enter Channel Title"
                                    label="Channel Title"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("title")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={5}>
                                <TextField
                                    id="channel_number"
                                    type="number"
                                    placeholder="Enter Channel Number"
                                    label="Channel Number"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("channel_number", {
                                        min: {
                                            value: 1,
                                            message: 'Value must be greater than 0'
                                        }
                                    })}
                                    variant="outlined"
                                    fullWidth
                                    helperText={errors.channel_number ? `${errors.channel_number.message}` : ""}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <TextField
                                    id="epg_map_id"
                                    placeholder="Enter EPG Map ID"
                                    label="EPG Map ID"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("epg_map_id")}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={5} >
                                <TextField
                                    // CHANNEL CODE
                                    id="channel_code"
                                    placeholder="Enter Channel Code"
                                    label="Channel Code"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("channel_code", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <div style={{ padding: 10 }}></div>
                        <Grid container spacing={2}>
                            <Grid item md={7}>
                                <TextField
                                    id="channel_description"
                                    placeholder="Enter Channel Description"
                                    label="Channel Description"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("channel_description")}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    id="locale"
                                    placeholder="Locale"
                                    label="Locale"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("locale", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />

                            </Grid>
                        </Grid>
                        <TitleSection title="Media & Options" description="Media & Options" paddingBottom={20}
                            paddingLeft={1} paddingTop={30} />
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <div className={classes.flex}>
                                    <div>
                                        {imageUrl ?
                                            <img width={90} src={`${BASE_ASSETS_URL}${imageUrl}`} />
                                            :
                                            <Avatar className={classes.large} alt="Logo"
                                                src="/static/images/avatar/1.jpg" />
                                        }
                                    </div>
                                    <div>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"
                                            style={{ marginTop: 23, marginLeft: 8 }}
                                        >
                                            Upload
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <TitleSection title="Country" description="Country" paddingBottom={20} paddingLeft={1}
                            paddingTop={30} />

                        <Grid item md={6}>
                            <Autocomplete
                                multiple={false}
                                id="countries"
                                options={countries}
                                value={selectedCountries}
                                getOptionLabel={(option: any) => option['Country']}
                                getOptionSelected={(option, value) => option?.Country === value?.Country}
                                onChange={(event, value: any) => {
                                    setSelectedCountries(value);
                                    // setDisabled(false);
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Country"
                                    />
                                )}
                            />
                        </Grid>
                        <TitleSection title="Genres" description="Genres" paddingBottom={20} paddingLeft={1}
                            paddingTop={30} />
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Autocomplete
                                    multiple
                                    id="genres-tags"
                                    options={genres}
                                    value={selectedGenres}
                                    getOptionLabel={(option: any) => option['name']}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    onChange={(event, value: any) => {
                                        setSelectedGenres(value);
                                        setDisabled(false);
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Genre"
                                        />
                                    )}
                                />
                                <CreateGenre />
                            </Grid>
                        </Grid>
                        <div style={{ padding: 20 }}>
                            <Divider />
                        </div>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!isDirty && disabled}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default EditChannel;