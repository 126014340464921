import React, { FC, useCallback, useEffect, useState } from 'react';
import { Title, useDataProvider, useNotify, useRefresh, useVersion } from 'react-admin';
import {
    Avatar,
    Button,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    TextField,
    Theme
} from '@material-ui/core';
// import Carousel from 'react-material-ui-carousel'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropzone, { useDropzone } from "react-dropzone";
import Banner from '../../assets/images/banner_placeholder.png';

// import types
import { AppState } from '../../types/Theme';

// import components
import TitleSection from '../../components/title/TitleSection';
import { BASE_URL } from "../../config";

type Inputs = {
    content_title: string,
    content_description: string,
    content_images: object,
    content_banner: object
};


const Create: FC = () => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30,
                "& .MuiFormHelperText-root": {
                    color: "red"
                }
            },
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
            flex: {
                display: 'flex'
            },
            error: {
                color: "red"
            },
            dropzone: {
                border: '1px dashed #ddd',
                padding: 30,
                borderRadius: 6,
                height: 50,
                width: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            images: {
                maxWidth: 150,
                height: 120,
                marginRight: 15,
                objectFit: 'contain',
                marginTop: 30
            }
        })
    );
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const [imageUrl, setImageUrl] = useState("");
    const [changeFiles, setChangeFiles] = useState<any>([]);
    const [changeimage, setChangeImage] = useState<any>({});

    const handleImage = (e) => {
        let url = createUrl(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
    }

    const createUrl = (file) => {
        const url = URL.createObjectURL(file);
        return url;
    }

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data: any) => {
        const formData: any = new FormData();

        data.content_images = changeFiles;
        data.content_banner = changeimage;

        data.content_images.forEach((item) => {
            formData.append("content_images", item);
        })

        formData.append("content_title", data.content_title);
        formData.append("content_description", data.content_description);
        formData.append("content_banner", data.content_banner);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
            body: formData
        };

        fetch(`${BASE_URL}/api/v1/content`, requestOptions)
            .then((data) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your content was successfully created', 'info');
                history.push("/content");
            });
    }

    return (
        <div className={classes.root}>
            <Title title="Create Channel" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={6}>
                    <Grid item md={7}>
                        <TitleSection title="Basic Details" description="Basic Details" paddingBottom={20}
                            paddingLeft={1} paddingTop={0} />
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <TextField
                                    id="title"
                                    placeholder="Enter Content Title"
                                    label="Content Title"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("content_title", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <div style={{ padding: 10 }}></div>
                        <Grid container spacing={2}>
                            <Grid item md={10}>
                                <TextField
                                    id="content_description"
                                    placeholder="Enter Content Description"
                                    label="Content Description"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("content_description", { required: true })}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                        <TitleSection title="Media & Options" description="Media & Options" paddingBottom={20}
                            paddingLeft={1} paddingTop={30} />
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className={classes.flex}>
                                    <div>
                                        {imageUrl ?
                                            <img width={200} height={120} style={{ objectFit: "cover" }} src={imageUrl} />
                                            :
                                            <img src={Banner} />
                                        }
                                    </div>
                                    <div style={{ alignSelf: 'center' }}>
                                        <Button
                                            size="large"
                                            color="primary"
                                            component="label"

                                        >
                                            Upload Banner
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    handleImage(e);
                                                }}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Dropzone
                                    onDrop={useCallback(acceptedFiles => {
                                        setChangeFiles(acceptedFiles);
                                    }, [])}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {!changeFiles.length ? <p className={classes.dropzone}>Drag and drop other images here, or click to select files</p> : null}
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                                                {changeFiles.map(file => (
                                                    <img className={classes.images} src={createUrl(file)} />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                        <div style={{ padding: 20 }}>
                            <Divider />
                        </div>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!changeFiles.length || !changeimage}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item md={5}>

                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default Create;