//list channels

import React, { ReactElement, Fragment, cloneElement } from 'react';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    FunctionField,
    TextField,
    EditButton,
    useListContext,
    CreateButton,
    ExportButton,
    TopToolbar,
    sanitizeListRestProps,
    Button,
    TextInput,
    Pagination,
} from 'react-admin';
import { Avatar, Typography, Box, makeStyles, Chip } from '@material-ui/core';

import { BASE_ASSETS_URL } from "../../config"

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const useStyles = makeStyles(theme => ({
    large: {
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 10,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    chip: {
        marginRight: 15
    }
}));

const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <div style={{ marginTop: 10 }}>
                <CreateButton basePath={basePath} />
            </div>
        </div>
    );
};


const channelsFilters = [
    <TextInput source="q" variant="outlined" label="Search" alwaysOn />
];

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button label="Genres" href="/#/genres" />
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};


const ResourceList = (props: ListProps): ReactElement => {
    const classes = useStyles();

    return (
        <div style={{ paddingLeft: 25 }}>
            <List
                empty={<Empty />}
                filters={channelsFilters}
                title="Live TV"
                actions={<ListActions />}
                {...props}
                perPage={10}
                pagination={<ListPagination />}
            >
                <Datagrid>
                    <FunctionField
                        render={(record: any) => {
                            if (record.icon_path) {
                                return (
                                    <img width={60} alt="Logo" src={`${BASE_ASSETS_URL}${record.icon_path}`} />
                                )
                            } else {
                                return (
                                    <Avatar className={classes.large} alt="Logo" src="/static/images/avatar/1.jpg" />
                                )
                            }
                        }}
                    />
                    <TextField label="NR" source="channel_number" />
                    <TextField label="Name" source="title" />
                    <TextField label="Channel Code" source="channel_code" />
                    <FunctionField
                        label="Genres"
                        render={(record: any) => {
                            if (record.genre) {
                                return (
                                    record.genre.map((genre) => (
                                        <Chip className={classes.chip} label={genre.name} />
                                    ))
                                )
                            }
                        }}
                    />
                    <TextField label="EPG ID" source="epg_map_id" />
                    <TextField label="Locale" source="locale" />
                    <EditButton />
                </Datagrid>
            </List>
        </div>
    )
}

export default ResourceList;