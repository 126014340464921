//CreateGenre channels

import React, { FC, useState, useCallback, useEffect } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider, useListParams } from 'react-admin';
import { makeStyles, createStyles, Theme, TextField, Dialog, DialogContent, Grid, Button, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types
import { AppState } from '../../../types/Theme';

//import components
import TitleSection from '../../../components/title/TitleSection';

type Inputs = {
    name: string
};

const CreateGenre: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formDiv: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 300
            }
        })
    );
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData();

        formData.append("name", data.name);

        dataProvider.create<any>(
            'genres',
            {
                data: formData,
            }
        ).then((res) => {
            refresh();
            notify("Genre was successfully created");
            handleClose();
        }).catch((error) => {
            notify(error.message, 'warning');
        })
    }

    return (
        <>
            <span onClick={handleClickOpen} style={{ cursor: "pointer", color: "#008DD9", fontWeight: "normal" }}>+ Create Genre</span>
            <Dialog
                onClose={handleClose}
                aria-labelledby="language-dialog"
                open={open}
                maxWidth={"lg"}
            >
                <DialogContent >
                    <Title title="Edit Package" />
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.formDiv}>
                        <TitleSection title="Create Genre" description="Create Genre" paddingBottom={10} paddingLeft={1} paddingTop={0} />
                        <TextField
                            id="name"
                            InputLabelProps={{ shrink: true }}
                            label="Genre Name"
                            placeholder="Enter Genre Name"
                            {...register("name")}
                            variant="outlined"
                            fullWidth
                        />
                        <div style={{ padding: 20 }}>
                            <Divider />
                        </div>
                        <Button
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Submit
                        </Button>
                    </form>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default CreateGenre;