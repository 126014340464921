//content

import React, { ReactElement, Fragment, cloneElement } from 'react';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import {
    BooleanField,
    Datagrid,
    List,
    ListProps,
    FunctionField,
    TextField,
    EditButton,
    useListContext,
    CreateButton,
    ExportButton,
    TopToolbar,
    sanitizeListRestProps,
    Button,
    TextInput,
} from 'react-admin';
import { Avatar, Typography, Box, makeStyles, Chip } from '@material-ui/core';

import { BASE_ASSETS_URL } from "../../config"

const useStyles = makeStyles(theme => ({
    large: {
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 10,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    chip: {
        marginRight: 15
    }
}));

const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: "center" }}>
            <HourglassEmptyRoundedIcon style={{ fontSize: 180, color: "grey" }} />
            <Typography style={{ fontSize: 25, color: "grey" }}>You don't have any {resource}</Typography>
            <Typography style={{ fontSize: 12, color: "grey" }}>Do you want to add one ?</Typography>
            <div style={{ marginTop: 10 }}>
                <CreateButton basePath={basePath} />
            </div>
        </div>
    );
};


const channelsFilters = [
    <TextInput source="q" variant="outlined" label="Search" alwaysOn />
];

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/*<Button label="Genres" href="/#/genres" />*/}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
        </TopToolbar>
    );
};


const ResourceList = (props: ListProps): ReactElement => {
    const classes = useStyles();

    return (
        <div style={{ paddingLeft: 25 }}>
            <List
                empty={<Empty />}
                filters={channelsFilters}
                title="Content"
                actions={<ListActions />}
                {...props}
                perPage={10}
            >
                <Datagrid>
                    <FunctionField
                        render={(record:any) => {
                            if (record.content_banner) {
                                return (
                                    <img width={60} alt="Logo" src={`${BASE_ASSETS_URL}${record.content_banner}`} />
                                )
                            } else {
                                return (
                                    <Avatar className={classes.large} alt="Logo" src="/static/images/avatar/1.jpg" />
                                )
                            }
                        }}
                    />
                    <TextField label="Title" source="content_title" />
                    <TextField label="Description" source="content_description" />
                    <EditButton />
                </Datagrid>
            </List>
        </div>
    )
}

export default ResourceList;