import * as React from 'react';
import { FC } from 'react';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import {
    linkToRecord,
    NumberField,
    TextField,
    useListContext,
    DatagridProps,
    Identifier,
} from 'react-admin';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Button, CardActions, Card, CardContent, Grid } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import EPGImagePlaceholder from '../../assets/images/epg_image_placeholder.png';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    }
}));

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const getColsForWidth = (width: any) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 6;
};

const LoadingGridList: FC<DatagridProps & { nbItems?: number }> = ({
    width,
    nbItems = 14,
}) => {
    const classes = useStyles();

    return (
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    );

};

const LoadedGridList: FC<DatagridProps> = ({ width }) => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();

    if (!ids || !data) return null;

    return (
        <List>
            {ids.map((id: Identifier) => (
                <ListItem
                    alignItems="flex-start"
                    style={{ backgroundColor: "#222", marginBottom: 5 }}
                    secondaryAction={
                        <Button color="primary" href={`/#/epg/${data[id]._id}`} startIcon={<EditIcon />}>Edit</Button>
                    }
                >
                    <ListItemAvatar style={{ marginRight: 15 }}>
                        <img style={{ width: 80, height: 53, borderRadius: 10 }} src={data[id].program_image ? data[id].program_image : EPGImagePlaceholder} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div>
                                <Typography style={{ fontSize: 12, color: "#d0c8c8" }}>
                                    {data[id].channel_name}
                                </Typography>
                                <Typography style={{ fontSize: 14, marginTop: -4, color: "white" }}>
                                    {data[id].program_name}
                                </Typography>
                            </div>
                        }
                        secondary={
                            <div>
                                <Typography style={{ color: "#ddd", fontSize: 10, marginBottom: 5 }}>
                                    {data[id].description ? data[id].description.substring(0, 280) + ' ...' : <span style={{ color: "#d65e5e" }}>No program description</span>}
                                </Typography>
                            </div>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

const ProgramList: FC<WithWidth> = ({ width }) => {
    const { loaded } = useListContext();

    return loaded ? (
        <LoadedGridList width={width} />
    ) : (
        <LoadingGridList width={width} />
    );

};

export default withWidth()(ProgramList);