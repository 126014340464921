import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles, List, ListItem, ListItemIcon, Divider, ListItemText } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import {
    useTranslate,
    usePermissions,
    MenuItemLink,
    MenuProps
} from 'react-admin';
import { useMediaQuery, Theme, Collapse } from '@material-ui/core';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HotelIcon from '@material-ui/icons/Hotel';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import PersonalVideo from '@material-ui/icons/PersonalVideo';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import Timeline from '@material-ui/icons/Timeline';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import MovieIcon from '@material-ui/icons/Movie';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DevicesIcon from '@material-ui/icons/Devices';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AppsIcon from '@material-ui/icons/Apps';
import MenuIcon from '@material-ui/icons/Menu';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PublishIcon from '@material-ui/icons/Publish';
import ViewListIcon from '@material-ui/icons/ViewList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// import states
import { AppState } from '../types/Theme';

// import utils
import { hasChildren } from "./utils/utils";

const Menu: FC = () => {
    const { permissions } = usePermissions();

    const themes = useSelector((state: AppState) => state.theme);
    const translate = useTranslate();
    const history = useHistory();

    const useStyles = makeStyles({
        root: {
            marginTop: 0,
            paddingTop: 10,
            backgroundColor: themes === 'light' ? "white" : "#222",
            height: '100%'
        },
        main: {
            marginTop: 0
        },
        icon: {
            color: "#7d7d80",
            marginBottom: 5
        },
        menuTitle: {
            marginLeft: 2,
            textTransform: "uppercase",
            color: themes === "light" ? "#333" : "rgb(255 255 255 / 92%)"
        },
        nested: {
            paddingLeft: 30
        },
        menuSubTitle: {
            marginLeft: -20,
            marginTop: 0,
            color: themes === "light" ? "#333" : "rgb(255 255 255 / 70%)"
        }
    });
    const classes = useStyles();
    const openMenu = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

    const [menuContentProviderState, setMenuContentProviderState] = useState([
        // {
        //     id: 1,
        //     title: "Dashboard",
        //     icon: <Timeline />,
        //     items: [
        //         {
        //             id: 1,
        //             title: "EPG Guide",
        //             icon: <Timeline />,
        //             to: "/"
        //         }
        //     ]
        // },
        {
            id: 2,
            title: "Content",
            icon: <Timeline />,
            items: [
                {
                    id: 2,
                    title: "EPG TV",
                    icon: <ViewListIcon />,
                    to: "/epg"
                },
                {
                    id: 3,
                    title: "Imports",
                    icon: <ImportExportIcon />,
                    to: "/imports"
                },
                {
                    id: 4,
                    title: "Channels",
                    icon: <LiveTvIcon />,
                    to: "/channels"
                },
                {
                    id: 5,
                    title: "Contents",
                    icon: <ContentCopyIcon />,
                    to: "/content"
                }
            ]
        },

        // {
        //     id: 4,
        //     title: "Settings",
        //     icon: <Timeline />,
        //     items: [
        //         {
        //             id: 5,
        //             title: "Users & Roles",
        //             icon: <GroupAddIcon />,
        //             to: "/users"
        //         }
        //     ]
        // }
    ]);

    const MenuItem = ({ item }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} />;
    };

    const SingleLevel = ({ item }) => {
        return (
            <ListItem
                component={Link}
                to={item.to}
                button
                className={classes.nested}
                selected={history.location.pathname == item.to}
            >
                <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                {openMenu ? <ListItemText className={classes.menuSubTitle} primary={item.title} /> : ""}
            </ListItem>
        );
    };

    const MultiLevel = ({ item }) => {
        const { items: children } = item;
        const [open, setOpen] = useState(true);

        const handleClick = () => {
            setOpen((prev) => !prev);
        };

        if (openMenu === true) {
            return (
                <React.Fragment>
                    <ListItem button onClick={handleClick}>
                        {openMenu ? <ListItemText className={classes.menuTitle} primary={item.title} /> : ""}
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={open}>
                        <List component="div">
                            {children.map((child, key) => (
                                <MenuItem key={key} item={child} />
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <List component="div">
                        {children.map((child, key) => (
                            <MenuItem key={key} item={child} />
                        ))}
                    </List>
                </React.Fragment>
            );
        }

    };

    return (
        <div className={classes.root}>
            {permissions == "distributor"
                ?
                <div>
                    {menuContentProviderState.map((item, key) => <MenuItem key={key} item={item} />)}
                </div>
                :
                null
            }
        </div>
    )
}

export default Menu;