import React from 'react';
import { Admin, Resource } from 'react-admin';

// import dataproviders
import authProvider from './providers/authprovider';
import dataProvider from './providers/data-provider';

// import layout
import { Layout } from './layout';
import { Dashboard } from './pages/dashboard';
import ThemeReducer from './reducer/ThemeReducer';

// import routes
import customRoutes from './routes/Routes';

// import resources
import users from './pages/users';
import epg from './pages/epg';
import channels from './pages/channels';
import genres from './pages/genres';
import contents from './pages/contents';

const systemAdminResources = [
	<Resource name="users" {...users} />,
	<Resource name="channels" {...channels} />,
	<Resource name="Genres" {...genres} />
]

const contentProviderResources = [
	<Resource name="users" {...users} />,
	<Resource name="epg" {...epg} />,
	<Resource name="channels" {...channels} />,
	<Resource name="Genres" {...genres} />,
	<Resource name="content" {...contents} />
]

const propertyResources = [
	<Resource name="users" {...users} />,
	<Resource name="channels" {...channels} />,
	<Resource name="Genres" {...genres} />
]

const resources = (permissions) => {
	switch (permissions) {
		case "distributor":
			return contentProviderResources;
		case "property":
			return propertyResources;
		case "system_admin":
			return systemAdminResources;
		default:
			return new Array();
	}
}

const App = () => {
	return (
		<Admin
			title="EPG - Administration Panel"
			dataProvider={dataProvider}
			authProvider={authProvider}
			customReducers={{ theme: ThemeReducer }}
			customRoutes={customRoutes}
			dashboard={Dashboard}
			layout={Layout}
			disableTelemetry
		>
			{resources}
		</Admin>
	)
};

export default App;