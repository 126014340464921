import React, { useState, useEffect, useCallback, FC, CSSProperties } from 'react';
import { useVersion, useTranslate, Title, useDataProvider, useRefresh, useNotify, useLogout } from 'react-admin';

const Dashboard = () => {
    return (
        <div>
            <Title title="Dashboard" />
            <div>
                
            </div>
        </div>
    )
}

export default Dashboard;