import * as React from 'react';
import { ReactElement } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    ImageField,
    List,
    ListProps,
    NullableBooleanInput,
    TextField,
    NumberField,
    SearchInput,
    EditButton
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    imgContainer: {
        '& img': {
            height: 40,
            width: 40,
        }
    }
}));

const GenreList = (props: ListProps): ReactElement => {
    const classes = useStyles();

    return (
        <List
            {...props}
            sort={{ field: 'last_seen', order: 'DESC' }}
            perPage={25}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label="name" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

export default GenreList;