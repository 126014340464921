//epg edit 

import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, Typography, TextField, Divider, Button, FormControlLabel, Checkbox, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";
import moment from 'moment';

// import types
import { AppState } from '../../types/Theme';

// import components
import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    program_id: string,
    channel: string,
    start_time: string,
    end_time: string,
    language: string,
    type: string,
    program_name: boolean,
    program_image: object,
    description: any,
};

const Edit: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30,
            },
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
            flex: {
                display: 'flex'
            }
        })
    );
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const [channels, setChannels] = useState<any>([]);
    const [selectedChannel, setSelectedChannel] = useState<string>("");

    //fetch channels
    const fetchChannels = useCallback(async () => {
        await dataProvider.getList<any>(
            'channels',
            {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            },
            {
                onSuccess: (response) => {
                    setChannels(response.data);
                }
            }
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchChannels();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { id } = useParams();

    const [epgDetails, setEpgDetails] = React.useState<any>({});

    const [imageUrl, setImageUrl] = React.useState("");
    const [changeImage, setChangeImage] = useState({});

    const handleImage = (e) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageUrl(url);
        setChangeImage(e.target.files[0]);
    }


    //fetch epg details
    const fetchEpgDetails = useCallback(async () => {
        await dataProvider.getOne(
            'epg/getById',
            {
                id: id
            },
            {
                onSuccess: async (response) => {
                    console.log(response);
                    setEpgDetails(response.data);
                    setImageUrl(response.data.program_image);
                    setValue("channel", response.data.channel);
                    setValue("program_id", response.data.program_id);
                    setValue("start_time", moment(response.data.start_time).format('YYYY-MM-DDTHH:SS'));
                    setValue("end_time", moment(response.data.end_time).format('YYYY-MM-DDTHH:SS'));
                    setValue("language", response.data.language);
                    setValue("type", response.data.type);
                    setValue("program_name", response.data.program_name);
                    setValue("description", response.data.description);
                }
            }
        );

    }, []);

    useEffect(() => {
        fetchEpgDetails();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.channel = selectedChannel;

        data.program_image = changeImage;

        formData.append("program_id", data.program_id);
        formData.append("channel", data.channel);
        formData.append("start_time", data.start_time);
        formData.append("end_time", data.end_time);
        formData.append("language", data.language);
        formData.append("type", data.type);
        formData.append("program_name", data.program_name);
        formData.append("description", data.description);
        if (Object.keys(data.program_image).length === 0 && data.program_image.constructor === Object === false) {
            formData.append("program_image", data.program_image);
        }

        dataProvider.update<any>(
            'epg',
            {
                id: id,
                data: formData,
                previousData: {
                    id: id
                }
            }).then((res) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your EPG was successfully created', 'info');
                console.log(data);
                history.push("/epg");
            }).catch((errors) => {
                notify('Error: Your EPG was not successfully created', 'warning');
                console.log(data);
            })
    }

    return (
        <div className={classes.root}>
            <Title title="Create EPG" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} />
                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <Autocomplete
                            //SELECTION OF CHANNELS 
                            id="channel"
                            options={channels}
                            getOptionLabel={(option: string) => option['title']}
                            onChange={(event, value: any) => {
                                // console.log(value, "value")
                                setSelectedChannel(value);
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Channel"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    placeholder="Select Channel"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <TextField
                            id="program_id"
                            InputLabelProps={{ shrink: true }}
                            label="Program ID"
                            placeholder="Enter Program ID"
                            {...register("program_id")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: 10 }}></div>
                <Grid container spacing={2}>
                    <Grid item md={7}>
                        <TextField
                            id="description"
                            InputLabelProps={{ shrink: true }}
                            label="Description"
                            placeholder="Enter EPG Description"
                            {...register("description")}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
                <TitleSection title="Duration & Language" description="Duration & Language" paddingBottom={10} paddingLeft={1} paddingTop={40} />
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="start_time"
                            placeholder="Start time"
                            type="datetime-local"
                            {...register("start_time")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="end_time"
                            placeholder="End Time"
                            type="datetime-local"
                            {...register("end_time")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: 10 }}></div>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="language"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter Language"
                            label="Language"
                            {...register("language")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <TitleSection title="Media Options" description="Media Options" paddingBottom={10} paddingLeft={1} paddingTop={40} />
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div className={classes.flex}>
                            <div>
                                {imageUrl ?
                                    <img width={90} src={imageUrl} />
                                    :
                                    <Avatar className={classes.large} alt="Poster" src="/static/images/avatar/1.jpg" />
                                }
                            </div>
                            <div>
                                <Button
                                    size="large"
                                    color="primary"
                                    component="label"
                                    style={{ marginTop: 23, marginLeft: 8 }}
                                >
                                    Upload Image
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            handleImage(e);
                                        }}
                                        hidden
                                    />
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div style={{ padding: 20 }}>
                    <Divider />
                </div>
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    Update EPG
                </Button>
            </form>
        </div >
    )
}

export default Edit;