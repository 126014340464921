import * as React from 'react';
import { Route } from 'react-router-dom';

// imports
import Imports from '../pages/imports/Imports';

export default [
    // imports
    <Route exact path="/imports" render={() => <Imports />} />,

];