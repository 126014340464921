import * as React from 'react';
import { FC } from 'react';
import { makeStyles, createStyles, Theme, Grid, Typography, Divider, Tooltip, Box, IconButton } from '@material-ui/core';
import { AppState } from '../../types/Theme';
import { useSelector, useDispatch } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';

interface Props {
    title: string,
    description: string,
    paddingTop: number,
    paddingBottom: number,
    paddingLeft: number
}

const TitleSection: FC<Props> = (props) => {
    const { title, description, paddingBottom, paddingLeft, paddingTop } = props;

    const flex = {
        flex: { display: 'flex' },
        flexColumn: { display: 'flex', flexDirection: 'column' },
        leftCol: { flex: 1, marginRight: '0.5em' },
        rightCol: { flex: 1, marginLeft: '0.5em' },
        singleCol: { marginTop: '1em', marginBottom: '1em' },
    };

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles(theme => ({
        root: {
            paddingTop: paddingTop,
            paddingBottom: paddingBottom,
            paddingLeft: paddingLeft
        },
        title: {
            color: themes === "light" ? "#333" : "rgb(255 255 255 / 92%)"
        },
        desc: {
            fontSize: 11,
            marginLeft: -5,
            marginTop: -11,
            color: themes === "light" ? "#333" : "rgb(255 255 255 / 92%)"
        }
    }));
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div style={flex.flex}>
                <div>
                    <Typography className={classes.title} gutterBottom>
                        {title}
                    </Typography>
                </div>
                <div>
                    <Tooltip title={description} placement="right-start">
                        <IconButton className={classes.desc}>
                            <HelpIcon style={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default TitleSection;