//epg  create

import React, { FC, useState, useEffect, useCallback } from 'react';
import { Title, useVersion, CRUD_UPDATE, useNotify, useRefresh, useTranslate, Notification, useDataProvider } from 'react-admin';
import { makeStyles, createStyles, Theme, Grid, Typography, Select, FormControl, InputLabel, MenuItem, TextField, Divider, Button, FormControlLabel, Checkbox, Box, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, SubmitHandler, useController, Controller } from "react-hook-form";

// import types
import { AppState } from '../../types/Theme';

// import components
import TitleSection from '../../components/title/TitleSection';

type Inputs = {
    program_id: string,
    channel: string,
    start_time: string,
    end_time: string,
    language: string,
    program_name: boolean,
    description: any,
};

const Create: FC = () => {

    const themes = useSelector((state: AppState) => state.theme);
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                padding: 30,
            },
            large: {
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                width: theme.spacing(10),
                height: theme.spacing(10),
            }
        })
    );

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const version = useVersion();
    const notify = useNotify();
    const refresh = useRefresh();
    const history = useHistory();

    const [channels, setChannels] = useState<any>([]);
    const [selectedChannel, setSelectedChannel] = useState<string>("");

    //fetch channels
    const fetchChannels = useCallback(async () => {
        await dataProvider.getList<any>(
            'channels',
            {
                filter: {},
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            },
            {
                onSuccess: (response) => {
                    setChannels(response.data);
                }
            }
        );
    }, [dataProvider]);

    useEffect(() => {
        fetchChannels();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, control, watch, setValue, formState: { errors } } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData: any = new FormData();

        data.channel = selectedChannel;

        formData.append("program_id", data.program_id);
        formData.append("channel", data.channel);
        formData.append("start_time", data.start_time);
        formData.append("end_time", data.end_time);
        formData.append("language", data.language);
        formData.append("program_name", data.program_name);
        formData.append("description", data.description);

        dataProvider.create(
            'epg',
            {
                data: formData

            }).then((res) => {
                const timer: ReturnType<typeof setTimeout> = setTimeout(() => refresh(), 1000);
                notify('Your EPG was successfully created', 'info');
                console.log(data);
                history.push("/epg");
            }).catch((errors) => {
                notify('Error: Your EPG was not successfully created', 'warning');
                console.log(data);
            })
    }

    return (
        <div className={classes.root}>
            <Title title="Create EPG" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <TitleSection title="Basic Details" description="Basic Details" paddingBottom={10} paddingLeft={1} paddingTop={0} />
                <Grid container spacing={3}>
                    <Grid item md={2}>
                        <Autocomplete
                            //SELECTION OF CHANNELS 
                            id="channel"
                            options={channels}
                            getOptionLabel={(option: string) => option['title']}
                            onChange={(event, value: any) => {
                                // console.log(value, "value")
                                setSelectedChannel(value);
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Channel"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    placeholder="Select Channel"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <TextField
                            id="program_name"
                            InputLabelProps={{ shrink: true }}
                            label="Program Name"
                            placeholder="Enter Program Name"
                            {...register("program_name")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2}>
                        <TextField
                            id="program_id"
                            InputLabelProps={{ shrink: true }}
                            label="Program ID"
                            placeholder="Enter Program ID"
                            {...register("program_id")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: 10 }}></div>
                <Grid container spacing={2}>
                    <Grid item md={7}>
                        <TextField
                            id="description"
                            InputLabelProps={{ shrink: true }}
                            label="Description"
                            placeholder="Enter EPG Description"
                            {...register("description")}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
                <TitleSection title="Duration & Language" description="Duration & Language" paddingBottom={20} paddingLeft={1} paddingTop={30} />
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="start_time"
                            label="Start time"
                            type="datetime-local"
                            defaultValue="2021-08-24T10:30"
                            {...register("start_time")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="end_time"
                            label="End Time"
                            type="datetime-local"
                            defaultValue="2021-08-25T10:30"
                            {...register("end_time")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: 10 }}></div>
                <Grid container spacing={2}>
                    <Grid item md={2}>
                        <TextField
                            id="language"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter Language"
                            label="Language"
                            {...register("language")}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <div style={{ padding: 20 }}>
                    <Divider />
                </div>
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    Submit
                </Button>
            </form>
        </div >
    )
}

export default Create;