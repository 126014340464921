import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import { Typography, Button, makeStyles, IconButton } from '@material-ui/core';

// import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    }
});


const CustomAppBar = (props: any) => {
    const classes = useStyles();

    return (
        <AppBar {...props} elevation={1}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {/* <Logo /> */}
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;